import axios from 'axios'
export default function(context) {
  // axios
  //   .get(
  //     `${process.env.apiUrl}/wp-json/syd/v3/pages?path=${context.params.path}`
  //   )
  //   .then(response => {})
  //   .catch(e => {
  //     console.log('err', e)
  //   })
}
