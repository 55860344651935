const crypto = require('crypto')

function sha1Hash(input) {
  const hash = crypto.createHash('sha1')
  hash.update(input)
  return hash.digest('hex')
}

export default function({ store, redirect, route, app }) {
  // If the user is not authenticated
  if (!store.$auth?.user) {
    // TODO: Maybe move to separate middleware
    // Check so we're not already on the login page "/"
    if (route.path !== '/' && route.path !== '/password-reset') {
      return redirect('/')
    }
  }

  if (process.client) {
    store.app.$gtm.push({
      event: 'pageview',
      page: {
        path: route.path,
        title: route.path
      }
    })
    const hash = crypto.createHash('sha1')
    const customerId = app.$auth?.$state?.loggedIn
      ? app.$auth.$state.user.id
      : ''
    // Create a SHA1 hash of the user ID
    const customerEmail = app.$auth?.$state?.loggedIn
      ? sha1Hash(app.$auth.$state.user.email)
      : ''

    ire('identify', {
      customerId,
      customerEmail
    })
  }
}

// export default function({ store, redirect }) {
// }
