



























































































import Vue from 'vue'
import Button from '@/components/v2/Button.vue'
import TextInput from '@/components/TextInput.vue'
import IconButton from '@/components/IconButton.vue'
import Checkbox from '@/components/Checkbox.vue'
import Debug from '@/components/Debug.vue'
import User from '@/classes/user'
export default Vue.extend({
  props: {
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    }
  },
  data(): any {
    return {
      loginData: {
        email: '',
        password: '',
        rememberMe: false
      },
      forgotPassword: false,
      errorMessage: '',
      resetPasswordError: false
    }
  },
  computed: {
    user(): User {
      return this.$auth.user
    },
    isLoggedIn(): Boolean {
      return this.$auth.loggedIn
    }
  },
  components: {
    Button,
    TextInput,
    IconButton,
    Checkbox,
    Debug
  },
  methods: {
    translate(s: string): string {
      return this.$i18n ? this.$i18n.t(s) : `Translatable string: ${s}`
    },
    logout(): void {
      this.$auth.logout()
    },
    login(): void {
      this.errorMessage = ''
      let loginData = {
        email: this.loginData.email,
        password: this.loginData.password,
        duration: this.loginData.rememberMe ? null : '+3 hours'
      }
      this.$auth.loginWith('local', { data: loginData }).then(
        (response: any) => {
          this.closeLogin()
          this.GtmLogin(true)
          this.$store.commit('nav/addMessage', this.$t("You're logged in!"))
          let path = '/my-account/subscription'
          this.$router.push(path)
        },
        (error: any) => {
          this.errorMessage = error.response.data.message
          this.GtmLogin(false)
        }
      )
    },
    GtmLogin(isSuccess: any): void {
      this.$gtm.push({
        event: 'login',
        login: {
          success: isSuccess
        },
        user: {
          id: this.user ? this.user.id : ''
        }
      })
    },
    passwordReset(): void {
      this.loginSignupError = ''
      this.resetPasswordError = ''
      this.$axios
        .post(`${process.env.apiUrl}/wp-json/syd/v1/users/password_reset`, {
          email: this.loginData.email
        })
        .then(
          (r: any) => {
            alert(`E-mail sent to ${this.loginData.email}`)
          },
          (error: any) => {
            if (error.response) {
              this.resetPasswordError = error.response.data.message
            }
          }
        )
    },
    closeLogin(): void {
      this.$emit('closeLogin')
    },
    toggleCheckbox(): void {
      this.loginData.rememberMe = !this.loginData.rememberMe
    }
  }
})
