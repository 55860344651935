
























import Vue from 'vue'
const _ = require('lodash')

export default Vue.extend({
  props: {
    info: {
      type: Object,
      default: () => {},
    },
  },
  data(): any {
    return {
      hidden: false,
    }
  },
  components: {},
  computed: {},
  methods: {},
  mounted() {},
})
