import Vue from 'vue'
import numeral from 'numeral'

// numeral.register('locale', 'sv', {
//   delimiters: {
//     thousands: ' ',
//     decimal: '.'
//   },
//   abbreviations: {
//     thousand: 't',
//     million: 'm',
//     billion: 'b',
//     trillion: 't'
//   },
//   ordinal : function (number) {
//     return number === 1 ? 's' : 'a';
//   },
//   currency: {
//     symbol: 'SEK'
//   }
// })
// numeral.locale('sv')

Vue.filter('currency', function(value) {
  return Math.round(+parseFloat(value).toFixed(2))
})

Vue.filter('klarnaCurrency', function(value) {
  return numeral(value / 100).value()
})
