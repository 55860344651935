//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    isChecked: {
      type: Boolean,
      default: false
    },
    dark: {
      type: Boolean,
      default: false,
    }
  },
  components: {},
  computed: {},
  methods: {
    toggleCheckbox() {
      this.$emit('toggleCheckbox')
    }
  }
}
