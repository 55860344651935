export default {
  routes: [
    {
      path: 'pages',
      type: 'page',
      route: (p) => `/${p.language_code != 'sv' ? p.language_code + '/' : '' }${p.slug}`,

      //apiUrl: '/wp-json/wp/v2/pages',
      apiUrl: ['/wp-json/wp/v2/pages', '/en/wp-json/wp/v2/pages'],
    },
    {
      path: 'products',
      type: 'product',
      route: (p) => `/${p.language_code == 'sv' ? 'produkter' : p.language_code + '/products' }/${p.slug}`,
      //apiUrl: '/wp-json/syd/v1/products',
      apiUrl: ['/wp-json/syd/v1/products'],
    }
  ]
}
