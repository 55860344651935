import { render, staticRenderFns } from "./CartContents.vue?vue&type=template&id=4d667321&scoped=true&"
import script from "./CartContents.vue?vue&type=script&lang=ts&"
export * from "./CartContents.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d667321",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SydV2Button: require('/opt/build/repo/components/v2/Button.vue').default,SydV2Sticker: require('/opt/build/repo/components/v2/Sticker.vue').default})
