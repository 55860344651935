









import Vue from 'vue'
const _ = require('lodash')

export default Vue.extend({
  props: {
    icon: {
      type: String,
      default: 'drop-filled'
    },
    color: {
      type: String,
      default: 'white'
    },
    classes: {
      type: Array,
      default: () => []
    },
    svgClasses: {
      type: Array,
      default: () => []
    },
    width: {
      type: String,
      default: '24'
    },
    height: {
      type: String,
      default: '24'
    }
  },
  data(): any {
    return {}
  },
  components: {},
  computed: {
    src(): string {
      let src = require(`assets/images/icons/${this.icon}.svg?raw`)

      let newSrc = src
        .split('<svg')
        .join(`<svg class="${this._svgClasses}"`)
        .split('<path')
        .join(`<path ref="svgIcon" class="text-${this.color}"`)
      if (this.width)
        newSrc = newSrc.split(/(width=".*?")/).join(`width="${this.width}"`)
      if (this.height)
        newSrc = newSrc.split(/(height=".*?")/).join(`height="${this.height}"`)

      return newSrc
    },
    _classes(): string {
      let classes = _.clone(this.classes)
      classes.push(`icon--${this.icon}`)
      classes.push(`text-${this.color}`)
      return classes.join(' ')
    },
    _svgClasses(): string {
      let classes = _.clone(this.svgClasses)
      return classes.join(' ')
    }
  },
  methods: {},
  mounted() {}
})
