


















































































































import Vue from 'vue'

export default Vue.extend({
  props: {
    subTotal: {
      type: Number,
      default: 0,
    },
    total: {
      type: Number,
      default: 0,
    },
    recurringItems: {
      type: Array,
      default: [],
    },
    recurringItemsPrice: {
      type: Number,
      default: 0,
    },
    oneTimeItems: {
      type: Array,
      default: [],
    },
    cartType: {
      type: String,
      default: '',
    },
  },
  data(): any {
    return {}
  },
  components: {},
  computed: {
    cartOptions(): any {
      return this.$store.state.options.cart
    },
    settings(): any {
      return this.$store.state.settings
    },
    orderInterval(): string {
      return this.settings.settings.order_interval || 4
    },
    orderIntervalUnit(): string {
      return this.settings.settings.order_interval_unit.toLowerCase() || 'month'
    },
  },
  methods: {
    removeProduct(item: any): void {
      this.$emit('removeProduct', item)
    },
    removeRecurringProducts(): void {
      this.$emit('removeRecurringProducts')
    },
  },
})
