//
//
//
//
//
//

export default {
  props: {
    caption: {
      type: String,
      default: 'Loading',
    },
    color: {
      type: String,
      default: 'white',
    },
  },
}
