













































































































































































import Vue from 'vue'
import axios from 'axios'
import SvgIcon from '../components/SvgIcon.vue'

export default Vue.extend({
  props: {},
  data() {
    return {}
  },
  components: {
    SvgIcon,
  },
  computed: {
    settings(): any {
      return this.$store.state.settings
    },
  },
  methods: {},
})
