



































import Vue from "vue";
import Debug from '../components/Debug.vue'

export default Vue.extend({
  props: ['error'],
  layout: 'empty',
  components: {
    Debug
  },
  data() {
    return {
      animationStep: 0,
      removeTrasitions: false,
    }
  },
  mounted() {
    let aniamtionDuration = 2000
    window.setInterval(() => {
      this.animationStep == 2 ? this.animationStep = 0 : this.animationStep += 1
      if(this.animationStep == 0) {
        this.removeTrasitions = true
      } else {
        this.removeTrasitions = false
      }
    }, 1800)
  },
  methods: {
    getPosition() {
      return `transform:translateX(-${100 / 3 * this.animationStep}%)`
    }
  }
});
