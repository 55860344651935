export default {
  state: () => ({
    messages: []
  }),
  mutations: {
    addMessage(state, message) {
      let id = _.uniqueId()
      state.messages.push({
        message: message,
        id: id
      })
      setTimeout(() => {
        this.commit('nav/removeMessage', id)
      }, 3000)
    },
    removeMessage(state, id) {
      let index = state.messages.findIndex(e => e.id == id)
      state.messages.splice(index, 1)
    }
  }
}
