









import Vue from "vue";

export default Vue.extend({
  props: {
    type: {
      type: String,
      default: "h1",
    },
    fontSize: {
      type: String,
      default: "",
    },
    lineHeight: {
      type: String,
      default: "",
    },
    caption: {
      type: String,
      default: "",
    },
    classes: {
      type: String,
      default: "",
    },
    textColor: {
      type: String,
      default: "red",
    },
    font: {
      type: String,
      default: "",
    },
  },
  data(): any {
    return {};
  },
  components: {},
  computed: {
    _classes(): String {
      const classes = [`syd-title`];
      classes.push(this.classes);
      classes.push(`text-${this.fontSize}`);
      classes.push(`font-${this.font}`);
      classes.push(`text-${this.textColor}`);
      if (this.lineHeight) classes.push(`leading-${this.lineHeight}`)
      return classes.join(" ");
    },
  },
  mounted() {
  },
  methods: {
  },
});
