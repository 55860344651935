export default function ({ store }) {
  // If the user is not authenticated
  // store.app.router.beforeEach((to, from, next) => {
  //   if(confirm('Are you sure?')) {
  //     next()
  //   } else {
  //     next(false)
  //   }
  // }) 
}
