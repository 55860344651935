










































































import Vue from 'vue'
const _ = require('lodash')

export default Vue.extend({
  props: {
    mainNav: {
      type: Object,
      default: {},
    },
    footerNav: {
      type: Object,
      default: {},
    },
    loggedIn: {
      type: Boolean,
      default: false,
    },
    toggleNavigationState: {
      type: Function,
      default: () => {},
    },
    cartLabel: {
      type: String,
      default(): string {
        return this.$t('cart').toString()
      },
    },
  },
  components: {},
  computed: {},
  methods: {
    close(): void {
      this.$emit('close')
    },
    toggleCart(): void {
      this.$emit('toggleCart')
    },
  },
})
