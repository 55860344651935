import Vue from 'vue'

export default ({ app }) => {
  Vue.filter('productHead', (product, settings) => {
    let title = `${product.title}`
    let languages = {
      sv_SE: 'sv',
      en_US: 'en',
      en: 'en',
      sv: 'sv'
    }
    let image = {
      src:
        product && product.featured_image && product.featured_image.large
          ? product.featured_image.large
          : settings.settings.share_fallback_image.url,
      width:
        product && product.featured_image
          ? product.featured_image.large_width
          : settings.settings.share_fallback_image.width,
      height:
        product && product.featured_image
          ? product.featured_image.large_height
          : settings.settings.share_fallback_image.height
    }
    return {
      title: title,
      titleTemplate: `%s - SYD`,
      htmlAttrs: {
        lang: languages[product.lang]
      },
      meta: [
        { hid: 'og:type', property: 'og:title', content: 'website' },
        { hid: 'og:title', property: 'og:title', content: title },
        { hid: 'og:image', property: 'og:image', content: image.src },
        {
          hid: 'og:image:width',
          property: 'og:image:width',
          content: image.width
        },
        {
          hid: 'og:image:height',
          property: 'og:image:height',
          content: image.height
        },
        { hid: 'og:image:alt', property: 'og:image:alt', content: title },
        {
          hid: 'description',
          name: 'description',
          content: `${product.description}`.replace(/<\/?[^>]+(>|$)/g, '')
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: `${product.description}`.replace(/<\/?[^>]+(>|$)/g, '')
        },
        {
          hid: 'og:image:secure_url',
          property: 'og:image:secure_url',
          content: image.src
        }
      ]
    }
  })

  Vue.filter('pageHead', (page, settings) => {
    console.log('page', page)
    let title = page && page.title ? page.title : ''
    let image = {
      src:
        page && page.featured_image && page.featured_image.large
          ? page.featured_image.large
          : settings.settings.share_fallback_image.url,
      width:
        page && page.featured_image
          ? page.featured_image.large_width
          : settings.settings.share_fallback_image.width,
      height:
        page && page.featured_image
          ? page.featured_image.large_height
          : settings.settings.share_fallback_image.height
    }
    return {
      title: title,
      titleTemplate: `%s - SYD`,
      htmlAttrs: {
        lang: `${page.language_code}`
      },
      meta: [
        { hid: 'og:type', property: 'og:title', content: 'website' },
        { hid: 'og:title', property: 'og:title', content: title },
        { hid: 'og:image', property: 'og:image', content: image.src },
        {
          hid: 'og:image:width',
          property: 'og:image:width',
          content: image.width
        },
        {
          hid: 'og:image:height',
          property: 'og:image:height',
          content: image.height
        },
        { hid: 'og:image:alt', property: 'og:image:alt', content: title },
        {
          hid: 'description',
          name: 'description',
          content: `${
            page && page.excerpt ? page.excerpt.rendered : ''
          }`.replace(/<\/?[^>]+(>|$)/g, '')
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: `${
            page && page.excerpt ? page.excerpt.rendered : ''
          }`.replace(/<\/?[^>]+(>|$)/g, '')
        },
        {
          hid: 'og:image:secure_url',
          property: 'og:image:secure_url',
          content: image.src
        }
      ],
      script: [
        {
          src: `https://s.apprl.com/js/apprl.js`,
          defer: true
        }
      ]
    }
  })
}
