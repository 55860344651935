//
//
//
//
//
//
//
//

import Navigation from '~/components/v2/Navigation.vue'
import FooterComponent from '~/components/Footer.vue'

export default {
  components: {
    Navigation,
    FooterComponent,
  },
}
