//
//
//
//
//
//
//

import Navigation from '~/components/v2/Navigation.vue'
import FooterComponent from '~/components/Footer.vue'
import CartService from '../services/cart'

export default {
  data() {
    return {
      cartService: new CartService(),
    }
  },
  components: {
    Navigation,
    FooterComponent,
  },
  computed: {
    menu() {
      return this.$store.state.menuData
    },
    user() {
      return this.$auth.user
    },
  },
  head() {
    let reg_se = /^\/mitt-konto/
    let reg_en = /^\/en\/my-account/
    return {
      bodyAttrs: {
        class:
          reg_se.test(this.$route.path) || reg_en.test(this.$route.path)
            ? 'bg-beige'
            : 'bg-blood',
      },
    }
  },
  mounted() {
    this.getCartIdByCookie()
    this.getUserTokenCookie()
  },
  methods: {
    getCartIdByCookie() {
      let cartId = this.$cookies.get('cartId')
      if (cartId) {
        this.cartService.getCart(cartId, this.$store).then((r) => {})
      }
      // this.$store.commit( 'cart/getCart', cartId[2] )
    },
    getUserTokenCookie() {
      // let userToken = this.$cookies.get("userToken")
      // if(userToken) {
      //   this.login(userToken)
      // }
    },
    login() {
      this.$auth
        .loginWith('local', { data: this.loginData })
        .then((response) => {
          this.$axios.setToken(response.data.token, 'Bearer')
          return this.$axios
            .get(`${process.env.apiUrl}/wp-json/syd/v1/users/me`)
            .then((r) => {
              this.$store.commit('user/login', r.data)
              this.$cookies.set('userToken', response.data.token, {})
            })
        })
    },
  },
}
