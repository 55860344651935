import Vue from 'vue'
import WPRoutes from '~/wp-routes.js'

Vue.filter('permalink', function (post) {
  if( post.object == 'custom' )
    return post.url

  let route = WPRoutes.routes.filter(p => p.type == post.object)
  route = route.length > 0 ? route[0].route( post ) : post.url
  return route
})
