import axios from 'axios'
import Cart from '../classes/cart'
import { ItemVariation } from '../classes/item'

export default class CartService {
    store: any = null
    app: any = null
    apiEndpoint = 'cart'
    getCart(id: Number, store: any): Promise<Cart> {
        return new Promise<Cart>(r => {
            axios.get(`${process.env.apiUrl }/wp-json/syd/v1/${this.apiEndpoint}/${id}`)
            .then(response => {
                if( response.status == 200 ) {
                    store.commit('cart/setCart', response.data)
                    r( response.data )
                } else {
                    // TODO: handle error if header is not 200
                }
            }, error => {
                console.error(`Cart with id ${id} returned error, clearing cart cookie`)
                store.commit('cart/resetCart')
            })
        })
    }
    addItem(item: ItemVariation, store: any) {
        // let i: Array<ItemVariation> = [...store.state.cart.items].filter(i => i.sku == item.sku)
        let items = JSON.parse(JSON.stringify(store.state.cart.items))
        if (items.some((i: ItemVariation) => i.sku == item.sku)) { // Item exists in cart - update
            items.filter((i: ItemVariation) => i.sku == item.sku).forEach((i: ItemVariation) => i.quantity = i.quantity + item.quantity)
        } else {
            items.push( item )
        }
        if( store.state.cart.id == 0 ) {
            // Create a new cart
            axios.post(`${process.env.apiUrl}/wp-json/syd/v1/${this.apiEndpoint}`, { items: items })
                .then(response => {
                    store.commit('cart/setCart', response.data)
                })
        } else {
            axios.put(`${process.env.apiUrl}/wp-json/syd/v1/${this.apiEndpoint}/${store.state.cart.id}`, { items: items })
                .then(response => {
                    store.commit('cart/setCart', response.data)
                })
        }
    }
}