


















import Vue from "vue";

export default Vue.extend({
  props: {
  },
  data(): any {
    return {};
  },
  components: {},
  computed: {},
});
