export default function({ app }) {
  // Set lang on page load
  app.$axios.defaults.params = {}
  app.$axios.defaults.params['lang'] = app.i18n.locale
  // onLanguageSwitched called right after a new locale has been set
  app.i18n.onLanguageSwitched = (oldLocale, newLocale) => {
    app.$axios.defaults.params['lang'] = newLocale

    app.$gtm.push({
      site: {
        websiteId: newLocale,
        websiteName: newLocale,
        environment: process.env.ENVIRONMENT,
        version: '1'
      },
      user: {
        isinternal: false,
        acceptedCookies: app.$cookies.get('acceptCookie') ? true : false
      },
      page: {
        path: app.router.currentRoute.path,
        title: app.router.currentRoute.path
      }
    })

    app.store.dispatch('storeGetSettings')

    app.$axios.get(`${process.env.apiUrl}/wp-json/syd/v1/products`).then(r => {
      app.store.commit('products/setAll', r.data)
    })
  }
}
