


















































































































import Vue from 'vue'
import _ from 'lodash'
import Cart from '~/classes/cart'

export default Vue.extend({
  props: {
    showCloseButton: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
  },
  data(): any {
    return {
      couponInputValue: '',
    }
  },
  computed: {
    cartOptions(): any {
      return this.$store.state.options.cart
    },
    classes(): String {
      const classes = []
      if (this.type == 'modal') classes.push(`bg-white text-blood p-6 h-full`)
      return classes.join(' ')
    },
    loading(): Boolean {
      return this.$store.state.newCart.loading
    },
    coupons(): any {
      return this.$store.state.newCart.applied_coupons
    },
    cart(): Cart {
      return this.$store.state.newCart.cart || {}
    },
    cartHasItems(): Boolean {
      return (
        this.recurringItems.filter((i: any) => i.quantity > 0).length ||
        this.oneTimeItems.filter((i: any) => i.quantity > 0).length
      )
    },
    recurringItems(): any {
      return Object.values(this.$store.state.newCart.cart || {}).filter(
        (o: any) => o.recurring_interval
      )
    },
    oneTimeItems(): any {
      return Object.values(this.$store.state.newCart.cart || {}).filter(
        (o: any) => o.recurring_interval == ''
      )
    },
    recurringItemsPrice(): any {
      return _.sum(
        this.recurringItems.map((item: any) => item.line_total + item.line_tax)
      )
    },
    cartSubTotals(): number {
      return _.sum(
        Object.keys(this.$store.state.newCart.cart)
          .filter(
            (key) =>
              this.$store.state.newCart.cart[key].product_id ||
              this.$store.state.newCart.cart[key].variation_id
          )
          .map((key) => this.$store.state.newCart.cart[key].line_subtotal)
      )
    },
    cartSubTaxTotals(): number {
      return _.sum(
        Object.keys(this.$store.state.newCart.cart)
          .filter(
            (key) =>
              this.$store.state.newCart.cart[key].product_id ||
              this.$store.state.newCart.cart[key].variation_id
          )
          .map((key) => this.$store.state.newCart.cart[key].line_subtotal_tax)
      )
    },
    cartTotals(): number {
      return _.sum(
        Object.keys(this.$store.state.newCart.cart)
          .filter(
            (key) =>
              this.$store.state.newCart.cart[key].product_id ||
              this.$store.state.newCart.cart[key].variation_id
          )
          .map((key) => this.$store.state.newCart.cart[key].line_total)
      )
    },
    cartTaxTotals(): number {
      return _.sum(
        Object.keys(this.$store.state.newCart.cart)
          .filter(
            (key) =>
              this.$store.state.newCart.cart[key].product_id ||
              this.$store.state.newCart.cart[key].variation_id
          )
          .map((key) => this.$store.state.newCart.cart[key].line_tax)
      )
    },
    subTotal(): number {
      return this.cartSubTotals + this.cartSubTaxTotals
    },
    total(): number {
      return this.cartTotals + this.cartTaxTotals
    },
    settings(): any {
      return this.$store.state.settings
    },
  },
  // watch: {
  //   $route() {
  //     this.closeCart()
  //   },
  // },
  mounted() {
    this.getCart()
  },
  methods: {
    gotoCheckout(): void {
      this.$router.push(
        this.localePath(this.$i18n.locale == 'en' ? '/checkout/' : '/kassa/')
      )
    },
    getCart(): void {
      this.$store.commit('newCart/setLoading', true)
      this.$axios
        .get(`${process.env.apiUrl}/wp-json/syd/v2/cart`, {
          withCredentials: true,
        })
        .then((response: any) => {
          this.$store.commit('newCart/update', response.data)
          this.$store.commit('newCart/setLoading', false)
        })
    },
    couponInputChange(value: String): void {
      this.couponCode = value
    },
    applyCoupon(): void {
      this.$axios
        .post(
          `${process.env.apiUrl}/wp-json/syd/v1/coupon/${this.couponInputValue}`,
          {},
          { withCredentials: true }
        )
        .then(
          (response: any) => {
            this.$store.commit('newCart/update', response.data)
            this.couponInputValue = ''
          },
          (error: any) => {
            this.$store.commit('nav/addMessage', error.response.data.message)
          }
        )
    },
    removeCoupon(coupon_code: String): void {
      this.$axios
        .delete(`${process.env.apiUrl}/wp-json/syd/v1/coupon/${coupon_code}`, {
          withCredentials: true,
        })
        .then(
          (response: any) => {
            this.$store.commit('newCart/update', response.data)
          },
          (error: any) => {
            this.$store.commit('nav/addMessage', error.response.data.message)
          }
        )
    },
    removeProduct(item: any): void {
      this.$store.commit('newCart/removeItem', item)
    },
    removeRecurringProducts(): void {
      this.$store.commit('newCart/removeItems', this.recurringItems)
    },
    routeToPackBuilder(): void {
      this.$router.push(this.settings.settings.pack_builder_page)
    },
    closeCart(): void {
      this.$emit('closeCart')
    },
  },
})
