import {Item} from '~/classes/item'
import _ from "lodash";

export default {
  state: () => [],

  mutations: {
    setAll(state, products) {
      state = Object.assign(state, products)
      // console.log('setting products', state)
    },
  },
  actions: {
    async storeGetProducts({ commit }) {
      const { data } = await this.$axios.get(`${process.env.apiUrl}/wp-json/syd/v1/products`)
      // console.log('data:', data)
      commit('setAll', data)
    },
  }
}
