import { render, staticRenderFns } from "./Cart.vue?vue&type=template&id=52a2213d&scoped=true&"
import script from "./Cart.vue?vue&type=script&lang=ts&"
export * from "./Cart.vue?vue&type=script&lang=ts&"
import style0 from "./Cart.vue?vue&type=style&index=0&id=52a2213d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52a2213d",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SydV2Button: require('/opt/build/repo/components/v2/Button.vue').default,SydLoader: require('/opt/build/repo/components/Loader.vue').default,SydV2CartContents: require('/opt/build/repo/components/v2/CartContents.vue').default})
