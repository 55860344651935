







































import Vue from 'vue'

export default Vue.extend({
  props: {
    caption: {
      type: String,
      default: 'They are super great!',
    },
    url: {
      type: String,
      default: '',
    },
    externalUrl: {
      type: String,
      default: '',
    },
    bgColor: {
      type: String,
      default: 'white',
    },
    textColor: {
      type: String,
      default: 'blood',
    },
    hoverColor: {
      type: String,
      default: 'yellow',
    },
    icon: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'medium', // mini || small || medium || medium-alt || large
    },
    rolling: {
      type: Boolean,
      default: true,
    },
    arrowButton: {
      type: Boolean,
      default: false,
    },
    classes: {
      type: Array,
      default: () => [],
    },
  },
  data(): any {
    return {}
  },
  components: {},
  computed: {
    _classes(): string {
      let classes = [
        'inline-flex flex-row cursor-pointer items-center whitespace-nowrap',
        `bg-${this.bgColor}`,
        'border',
        `border-${this.textColor}`,
        `text-${this.textColor}`,
        `hover:bg-${this.hoverColor}`,
        `hover:text-${this.hoverColor == 'yellow' ? 'blood' : this.textColor}`,
      ]
      if (this.icon) classes.push('justify-between')
      if (this.size == 'micro')
        classes = classes.concat(['text-xs', 'px-3', 'py-1', 'rounded-3xl'])
      if (this.size == 'mini')
        classes = classes.concat(['text-xs', 'px-4', 'py-1.5', 'rounded-3xl'])
      if (this.size == 'small')
        classes = classes.concat(['text-sm', 'px-6', 'py-2.5', 'rounded-3xl'])
      if (this.size == 'medium')
        classes = classes.concat([
          'uppercase',
          'text-sm',
          'px-8',
          'py-3',
          'rounded-3xl',
        ])
      if (this.size == 'medium-alt') {
        classes = classes.concat([
          'text-sm',
          'pl-8',
          'pr-16',
          'py-3',
          'rounded-3xl',
        ])
      }

      if (this.size == 'large')
        classes = classes.concat([
          'uppercase',
          'text-base',
          'px-8',
          'py-3',
          'rounded-3xl',
        ])

      let rolling = this.rolling

      if (this.arrowButton) {
        rolling = false
        classes = classes.concat(['button--arrow overflow-hidden relative'])
      } else {
        classes = classes.concat(['gap-4'])
      }

      if (rolling) classes = classes.concat(['button--rolling overflow-hidden'])

      classes = this.classes.concat(classes)
      return classes.join(' ')
    },
  },
  mounted() {},
  methods: {
    clicked(): void {
      if (this.url.length) {
        this.$router.push(this.url)
      } else {
        this.$emit('clicked')
      }
    },
  },
})
