import Vue from 'vue'
import { split } from 'lodash'

export default ({ app }) => {
  Vue.filter('recurring', function (recurringString) {
    if( recurringString == 'null' )
      return app.i18n.t('buyOnce')
    let recurring = split(recurringString, ' ');
    let intervals = {
      '+1': app.i18n.t('recurring.each'),
      '+2': `${app.i18n.t('recurring.two')}`,
      '+3': `${app.i18n.t('recurring.three')}`,
      '+4': `${app.i18n.t('recurring.four')}`,
      '+5': `${app.i18n.t('recurring.five')}`,
      '+6': `${app.i18n.t('recurring.six')}`,
    }
    return `${intervals[recurring[0]]} ${app.i18n.t(`recurring.${recurring[1]}`)}`
  })
}