//
//
//

import SvgIcon from '../components/SvgIcon.vue'
export default {
  props: {
    icon: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: ''
    },
    scaleOnHover: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  components: {
    SvgIcon
  },
  computed: {},
  methods: {
    onClick: function( $event ) {
      this.$emit('onClick')
    },
    getButtonClass() {
      let classes = `icon-button--${this.size} icon-button--${this.color} icon-button--${this.icon}`
      if(this.scaleOnHover)
        classes = `${classes} icon-button--scale-hover`
      if(this.disabled)
        classes = `${classes} icon-button--disabled`
      return classes
    }
  }
}
