import axios from 'axios'

export default {
  state: () => ({
    menuData: null,
    settings: null,
    pageTitle: 'Test',
    layout: 'default',
    navigations: {
      en: {
        header_menu: [],
        footer_menu: []
      },
      sv: {
        header_menu: [],
        footer_menu: []
      }
    }
  }),
  mutations: {
    // TODO:LAYOUT
    setLayout(state, layout) {
      state.layout = layout
    },
    setMenuData(state, menuData) {
      state.menuData = menuData
    },
    setSettingsData(state, settings) {
      state.settings = settings
    },
    setOptionsData(state, options) {
      state.options = options
    },
    setPageTitle(state, pageTitle) {
      state.pageTitle = pageTitle
    },
    setNavigationsData(state, navigations) {
      state.navigations = navigations
    }
  },
  actions: {
    async nuxtServerInit({ commit, dispatch, getters }, args) {
      await dispatch('storeGetSettings')
      await dispatch('storeGetOptions')

      if (!args.payload || !args.payload.navigation) {
        await dispatch('storeGetNavigations')
      } else {
        commit('setNavigationsData', args.payload.navigation)
      }

      // await dispatch('storeGetProducts')
      this.$gtm.push({
        site: {
          websiteId: this.$i18n.locale,
          websiteName: this.$i18n.locale,
          environment: process.env.ENVIRONMENT,
          version: '1'
        },
        user: {
          isinternal: false,
          acceptedCookies: this.$cookies.get('acceptCookie') ? true : false
        },
        page: {
          path: this.app.router.currentRoute.path,
          title: this.app.router.currentRoute.path
        }
      })
    },
    async storeGetNavigations({ commit }) {
      const sv = await this.$axios.get(
        `${process.env.apiUrl}/wp-json/syd/v1/navigation`
      )
      const en = await this.$axios.get(
        `${process.env.apiUrl}/en/wp-json/syd/v1/navigation`
      )
      const nav = {
        sv: sv.data,
        en: en.data
      }

      commit('setNavigationsData', nav)
    },
    // async storeGetProducts({ commit }) {
    //   const { data } = await this.$axios.get(`${process.env.apiUrl}/wp-json/syd/v1/products`)
    //   // console.log('products data:', `${process.env.apiUrl}/wp-json/syd/v1/products`, data)
    //   commit('products/setAll', data)
    // },
    async storeGetSettings({ commit }) {
      const { data } = await this.$axios.get(
        `${process.env.apiUrl}/wp-json/syd/v2/settings/settings`
      )
      commit('setSettingsData', data)
    },
    async storeGetOptions({ commit }) {
      const { data } = await this.$axios.get(
        `${process.env.apiUrl}/wp-json/syd/v3/options`
      )
      commit('setOptionsData', data)
    }
  }
}
