//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Navigation from '~/components/v2/Navigation.vue'
import FooterComponent from '~/components/Footer.vue'
import CartService from '../services/cart'

export default {
  data() {
    return {
      cartService: new CartService()
    }
  },
  components: {
    Navigation,
    FooterComponent
  },
  computed: {
    user() {
      return this.$auth.user
    },
    isLoggedIn() {
      return this.$auth.loggedIn
    },
    isHome() {
      return ['/', '/en/'].includes(this.$route.path)
    },
    menuItems: () => {
      let path = '/my-account'
      return [
        {
          label: 'Your Subscription',
          to: {
            path: `${path}/${'subscription'}/`
          }
        },
        {
          label: 'Your Account',
          to: {
            path: `${path}/${'account'}/`
          }
        },
        {
          label: 'Order History',
          to: {
            path: `${path}/${'order-history'}/`
          }
        },
        {
          label: 'Log Out',
          to: { path: `/account/logout/` }
        }
      ]
    }
  },
  head() {
    return {
      bodyAttrs: {
        class: 'bg-white'
      }
    }
  },
  mounted() {
    this.getCartIdByCookie()
    this.getUserTokenCookie()
  },
  methods: {
    getCartIdByCookie() {
      let cartId = this.$cookies.get('cartId')
      if (cartId) {
        this.cartService.getCart(cartId, this.$store).then(r => {})
      }
      // this.$store.commit( 'cart/getCart', cartId[2] )
    },
    getUserTokenCookie() {
      // let userToken = this.$cookies.get("userToken")
      // if(userToken) {
      //   this.login(userToken)
      // }
    },
    login() {
      this.$auth.loginWith('local', { data: this.loginData }).then(response => {
        this.$axios.setToken(response.data.token, 'Bearer')
        return this.$axios
          .get(`${process.env.apiUrl}/wp-json/syd/v1/users/me`)
          .then(r => {
            this.$store.commit('user/login', r.data)
            this.$cookies.set('userToken', response.data.token, {})
          })
      })
    }
  }
}
