import { render, staticRenderFns } from "./Navigation.vue?vue&type=template&id=53f97a2e&scoped=true&"
import script from "./Navigation.vue?vue&type=script&lang=ts&"
export * from "./Navigation.vue?vue&type=script&lang=ts&"
import style0 from "./Navigation.vue?vue&type=style&index=0&id=53f97a2e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53f97a2e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SydV2MenuToggle: require('/opt/build/repo/components/v2/MenuToggle.vue').default,SydV2LogoAnimation: require('/opt/build/repo/components/v2/LogoAnimation.vue').default,SydV2SvgIcon: require('/opt/build/repo/components/v2/SvgIcon.vue').default,SydV2Login: require('/opt/build/repo/components/v2/Login.vue').default,SydV2ModalCallout: require('/opt/build/repo/components/v2/ModalCallout.vue').default,SydV2Cart: require('/opt/build/repo/components/v2/Cart.vue').default,SydV2MobileMenu: require('/opt/build/repo/components/v2/MobileMenu.vue').default})
