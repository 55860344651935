









import Vue from 'vue'
const _ = require('lodash')

export default Vue.extend({
  props: {
    svgCode: {
      type: String,
      default: ''
    },
    align: {
      type: String,
      default: ''
    },
    classes: {
      type: String,
      default: ''
    },
    translateX: {
      type: String,
      default: null
    },
    translateY: {
      type: String,
      default: null
    },
    fitIn: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  computed: {
    _classes(): any {
      const classes = []
      classes.push(this.classes)
      classes.push('uppercase')
      if (!this.fitIn) classes.push('p-8')
      if (this.align && this.align.length) {
        classes.push('absolute z-10 transform')
        if (this.align.includes('top'))
          classes.push(
            `top-0 ${
              this.translateY
                ? `translate-y-${this.translateY}`
                : '-translate-y-1/4 '
            }`
          )
        if (this.align.includes('bottom'))
          classes.push(
            `bottom-0 ${
              this.translateY
                ? `translate-y-${this.translateY}`
                : '-translate-y-1/4 '
            }`
          )
        if (this.align.includes('1/3-from-top')) classes.push('top-1/3')
        if (this.align.includes('right'))
          classes.push(
            `right-0 ${
              this.translateX
                ? `translate-x-${this.translateX}`
                : 'translate-x-1/4'
            }`
          )
        if (this.align.includes('left'))
          classes.push(
            `left-0 ${
              this.translateX
                ? `translate-x-${this.translateX}`
                : '-translate-x-1/4'
            }`
          )
      }
      return classes.join(' ')
    }
  },
  methods: {}
})
