export default {
  state: () => ({
    translations: {},
  }),

  mutations: {
    setTranslations(state, translations) {
      state.translations = translations
    },
  }
}
