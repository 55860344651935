//
//
//
//
//
//
//
//
//
//
//
//
//

const _ = require('lodash')
export default {
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    modifier: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      focus: false,
      v: _.clone(this.value),
    }
  },
  components: {},
  computed: {
    textInputClass: function () {
      let styles = `text-input--${this.modifier} text-input--${this.color}`
      if (this.focus || this.v) {
        styles += ' text-input--has-value'
      }
      return styles
    },
  },
  methods: {
    change: function () {
      this.$emit('update:value', this.v)
    },
    onKeyupEnter: function() {
      this.$emit('onKeyupEnter', this.v)
    }
  },
}
