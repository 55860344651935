






import Vue from "vue";

export default Vue.extend({
  props: {
    object: {
      default: {}
    },
    title: {
      type: String,
      default: 'Debug'
    },
  },
  data(): any {
    return {
      expanded: false
    }
  },
  components: {
  },
  computed: {
    o: function(): string {
      return JSON.stringify(this.object, null, 4)
    },
  },
  methods: {
    toggleExpanded() {
      this.expanded = !this.expanded
    }
  }
});
