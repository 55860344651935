import _ from 'lodash'

export const state = () => ({
  cart: {},
  loading: true,
  applied_coupons: {},
  coupon_discount_totals: {},
  coupon_discount_tax_totals: {}
})

export const mutations = {
  update(state, cart) {
    state.cart = cart.cart_contents
    state.applied_coupons = cart.applied_coupons
    state.coupon_discount_totals = cart.coupon_discount_totals
    state.coupon_discount_tax_totals = cart.coupon_discount_tax_totals
  },
  resetCart(state) {
    state.cart = {}
    state.applied_coupons = {}
    state.coupon_discount_totals = {}
    state.coupon_discount_tax_totals = {}
  },
  addItem(state, item) {
    this.$gtm.push({
      event: item.quantity > 0 ? 'addToCart' : 'removeFromCart',
      currencyCode: 'SEK',
      add: {
        products: [
          {
            name: item.name,
            id: item.id,
            quantity: item.quantity
          }
        ]
      }
    })
    state.cart[item.key] = item
    delete state.cart[item.temp_key]
  },
  removeItem(state, item) {
    state.cart[item.key].quantity = 0
    console.log('removeItem', item)
    this.commit('newCart/setLoading', true)
    this.dispatch('newCart/updateCartAtApi', state)
  },

  removeItems(state, items) {
    items.forEach(item => {
      state.cart[item.key].quantity = 0
    })
    this.commit('newCart/setLoading', true)
    this.dispatch('newCart/updateCartAtApi', state)
  },

  addItemsToCart(state, items) {
    this.$fb.track('AddToCart')

    items.forEach(item => {
      let id = _.uniqueId('temp_')
      item.key = item.key ? item.key : id
      this.commit('newCart/addItem', item)
    })
    // this.commit('newCart/setLoading', true)
    this.dispatch('newCart/updateCartAtApi', state)
  },
  addItemToCart(state, item) {
    let id = _.uniqueId('temp_')
    item.key = id
    this.commit('newCart/addItem', item)
    this.commit('newCart/setLoading', true)
    this.dispatch('newCart/updateCartAtApi', state)
  },
  updateCartItemQuantity(state, item) {
    item.quantity = Math.max(item.quantity, 0)
    this.commit('newCart/addItem', item)
    // this.commit('newCart/setLoading', true)
    this.dispatch('newCart/updateCartAtApi', state)
  },
  setLoading(state, loading) {
    state.loading = loading
  }
}

export const actions = {
  incrementAsync({ commit }) {
    setTimeout(() => {
      commit('increment')
    }, 1000)
  },
  updateCartAtApi: _.debounce(async function(store) {
    this.commit('newCart/setLoading', true)
    let itemsToAdd = []
    let itemsToUpdate = []
    Object.keys(store.state.cart).map(key => {
      let item = store.state.cart[key]
      if (item.key && item.key.substring(0, 5) == 'temp_') itemsToAdd.push(item)
      else itemsToUpdate.push(item)
    })
    try {
      if (itemsToAdd.length > 0) {
        await this.$axios
          .put(
            `${process.env.apiUrl}/wp-json/syd/v2/cart/add`,
            { items: itemsToAdd },
            { withCredentials: true }
          )
          .then(response => {
            // store.commit('update', response.data.cart)
            response.data.errors.forEach(e => {
              this.app.store.commit('nav/addMessage', e)
            })
          })
      }
      await this.$axios
        .put(
          `${process.env.apiUrl}/wp-json/syd/v2/cart/update_item_quantity`,
          { items: itemsToUpdate },
          { withCredentials: true }
        )
        .then(response => {
          // const errors = response.errors.filter(result => (result instanceof Error))
          store.commit('update', response.data.cart)
          response.data.errors.forEach(e => {
            this.app.store.commit('nav/addMessage', e)
          })
        })
      this.app.store.commit('nav/addMessage', `Cart updated`)
    } catch (error) {
      this.app.store.commit('nav/addMessage', 'Something went wrong')
    }
    this.commit('newCart/setLoading', false)
    // const promises = Object.keys(store.state.cart).map(key => {
    //   i = i + 1
    //   let item = store.state.cart[key]
    //   if (item.key && item.key.substring(0, 5) !== 'temp_')
    //     return {
    //       url: `${process.env.apiUrl}/wp-json/syd/v2/cart/update_item_quantity/${key}/${item.quantity}`,
    //       payload: {},
    //     };
    //   else
    //     return {
    //       url: `${process.env.apiUrl}/wp-json/syd/v2/cart/add/${item.id}/${item.quantity}`,
    //       payload: { recurring_interval: item.recurring_interval },
    //     }
    // })

    // let sendToApi = (c) => {
    //   if(c.length == 0) {
    //     store.commit('setLoading', false)
    //     this.$axios.get(`${process.env.apiUrl}/wp-json/syd/v2/cart`, {
    //       withCredentials: true,
    //     }).then((response) => {
    //       store.commit("update", response.data);
    //       store.commit('setLoading', false)
    //     })
    //   } else {
    //     let call = c.pop()
    //     this.$axios.put(call.url, call.payload, { withCredentials: true }).then((response) => {
    //       _.delay(() => { sendToApi(c) }, 100)
    //     })
    //   }
    // }
    // let sendToApi = (c) => {
    //   return c.reduce((p, file) => {
    //     return this.$axios.put(file.url, file.payload, { withCredentials: true }).then((response) => {
    //       const errors = response.filter(result => (result instanceof Error))
    //       const successes = response.filter(result => !(result instanceof Error))
    //       store.commit('updateErrors', errors.map(e => e.response.data.message))
    //       // _.delay(() => { sendToApi(file) }, i++ * 1000)
    //     });
    //   }, Promise.resolve()); // initial
    // };
    // sendToApi(promises)

    // Promise.all(
    //   promises.map(p => p.catch(e => e))
    // ).catch(() => {
    //   return promises
    // }).then(response => {
    //   const errors = response.filter(result => (result instanceof Error))
    //   const successes = response.filter(result => !(result instanceof Error))
    //   store.commit('updateErrors', errors.map(e => e.response.data.message))
    //   successes.forEach(s => {
    //     if (+s.data.quantity == 0)
    //       store.commit('removeItem', s.data)
    //   })
    //   this.$axios.get(`${process.env.apiUrl}/wp-json/syd/v2/cart`, {
    //     withCredentials: true,
    //   }).then((response) => {
    //     store.commit("update", response.data);
    //     store.commit('setLoading', false)
    //   })
    // })
  }, 1000)
}
