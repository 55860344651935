






































































































import Vue from 'vue'
import axios from 'axios'
import SvgIcon from '../../components/SvgIcon.vue'

export default Vue.extend({
  props: {
    bringUp: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {}
  },
  components: {
    SvgIcon,
  },
  computed: {
    footerNav(): any {
      return {
        items: [
          {
            label: this.$t('home'),
            to: this.$i18n.locale == 'en' ? '/en/' : '/',
          },
          {
            label: this.$t('contact'),
            to: this.$i18n.locale == 'en' ? '/en/contact/' : '/contact/',
          },
          // {
          //   label: this.$t('newsletter'),
          //   to: this.$i18n.locale == 'en' ? '/en/newsletter/' : '/nyhetsbrev/',
          // },
          {
            label: this.$t('faq'),
            to: this.$i18n.locale == 'en' ? '/en/faq/' : '/faq/',
          },
          {
            label: this.$t('shippingAndReturns'),
            to:
              this.$i18n.locale == 'en'
                ? '/en/shipping-and-returns/'
                : '/leveranser-och-returer/',
          },
          {
            label: this.$t('termsAndConditions'),
            to:
              this.$i18n.locale == 'en'
                ? '/en/terms-and-conditions/'
                : '/allmanna-villkor/',
          },
        ],
      }
    },
    settings(): any {
      return this.$store.state.settings
    },
  },
  methods: {},
})
