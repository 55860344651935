

























































































































































































































































































































































import Vue from 'vue'

export default Vue.extend({
  props: {
    isProductPage: {
      type: Boolean,
      default: false,
    },
    transparent: {
      type: Boolean,
      default: false,
    },
  },
  data(): any {
    return {
      loginActive: false,
      cartActive: false,
      mobileMenuActive: false,
      navHeight: null,
      cookiesAccepted: true,
      overlayActive: false,
      inverted: false,
      siteMessage: true,
      hoverLogin: false,
      smileyBlink: false,
      scrollTop: 0,
      scrollingUp: true,
    }
  },
  watch: {
    $route() {
      this.closeAll()
    },
  },
  computed: {
    getLoginText(): String {
      if (this.stringSettings) {
        if (this.$i18n.locale == 'en' && this.stringSettings.en_login_text)
          return this.stringSettings.en_login_text
        if (this.$i18n.locale == 'sv' && this.stringSettings.sv_login_text)
          return this.stringSettings.sv_login_text
      }
      return ''
    },
    stringSettings(): any {
      return this.$store.state.options.strings
    },
    calloutOptions(): any {
      return this.$store.state.options.callouts
    },
    mainNav(): any {
      return {
        items: this.$store.state.navigations[this.$i18n.locale].header_menu.map(
          (navItem: any) => {
            let isScroll = navItem.target.indexOf('#') == 0
            let item = {
              label: navItem.title,
              to: !isScroll ? { path: navItem.target } : false,
              scrollTo: isScroll ? navItem.target : false,
            }
            if (!isScroll) {
              delete navItem.scrollTo
            }
            return item
          }
        ),
      }
    },
    subNav() {
      const regexList = [
        /\/mitt-konto/,
        /\/mitt-konto\//,
        /\/en\/my-account\//,
        /\/en\/my-account/,
      ]
      const isMatch = regexList.some((rx) => rx.test(this.$nuxt.$route.path))
      // Account Page
      if (isMatch) {
        let path = this.$i18n.locale == 'en' ? '/en/my-account' : '/mitt-konto'
        return {
          items: [
            {
              label: this.$t('Your Subscription'),
              to: {
                path: `${path}/${
                  this.$i18n.locale == 'en' ? 'subscription' : 'prenumeration'
                }/`,
              },
            },
            {
              label: this.$t('Your Account'),
              to: {
                path: `${path}/${
                  this.$i18n.locale == 'en' ? 'account' : 'konto'
                }/`,
              },
            },
            {
              label: this.$t('Order History'),
              to: {
                path: `${path}/${
                  this.$i18n.locale == 'en' ? 'order-history' : 'order-historik'
                }/`,
              },
            },
            {
              label: this.$t('Log Out'),
              to: { path: `/account/logout/` },
            },
          ],
        }
      }
      let activeMenuItem = this.$store.state.navigations[
        this.$i18n.locale
      ].header_menu.find((navItem: any) => {
        let target = this.$nuxt.$route.path.split('/')
        return navItem.target == `/${target[1]}/`
      })
      if (activeMenuItem) {
        return {
          items: activeMenuItem.children.map((navItem: any) => {
            let isScroll = navItem.target.indexOf('#') == 0
            let item = {
              label: navItem.title,
              to: !isScroll ? { path: navItem.target } : false,
              scrollTo: isScroll ? navItem.target : false,
            }
            if (!isScroll) {
              delete navItem.scrollTo
            }
            return item
          }),
        }
      }

      return { items: [] }
    },
    footerNav(): any {
      return {
        items: [
          {
            label: this.$t('contact'),
            to: this.$i18n.locale == 'en' ? '/en/contact/' : '/contact/',
          },
          {
            label: this.$t('faq'),
            to: this.$i18n.locale == 'en' ? '/en/faq/' : '/faq/',
          },
          {
            label: this.$t('shippingAndReturns'),
            to:
              this.$i18n.locale == 'en'
                ? '/en/shipping-and-returns/'
                : '/leveranser-och-returer/',
          },
          {
            label: this.$t('termsAndConditions'),
            to:
              this.$i18n.locale == 'en'
                ? '/en/terms-and-conditions/'
                : '/allmanna-villkor/',
          },
        ],
      }
    },
    user(): any {
      return this.$auth.user
    },
    isLoggedIn(): Boolean {
      return this.$auth.loggedIn
    },
    navMessages(): Array<string> {
      return this.$store.state.nav.messages || []
    },
    cart(): any {
      return this.$store.state.newCart.cart || []
    },
    cartLoading(): boolean {
      return this.$store.state.newCart.loading
    },
    cartLabel(): string {
      return this.cartActive ? this.$t('close') : this.$t('cart')
    },
    showLogin(): boolean {
      return this.loginActive && !this.$auth.user
    },
    showCart(): Boolean {
      return this.cartActive
    },
    showMobileMenu(): Boolean {
      return this.mobileMenuActive
    },
    cartAmount(): number {
      let amount = 0
      Object.keys(this.cart).forEach((item) => {
        amount += +this.cart[item].quantity
      })
      return amount
    },
    settings(): any {
      return this.$store.state.settings
    },
  },
  mounted() {
    if (this.$cookies.get('siteMessage')) {
      this.siteMessage = false
    }
    if (!this.$cookies.get('acceptCookie')) {
      this.cookiesAccepted = false
    }
    this.getCart()

    setInterval(() => {
      this.smileyBlink = true
      setTimeout(() => {
        this.smileyBlink = false
      }, 1000)
    }, 4000)
  },
  beforeMount() {
    if (process.client) {
      this.closeAll()
      window.addEventListener('scroll', this.handleScroll)
    }
  },
  beforeDestroy() {
    if (process.client) {
      window.removeEventListener('scroll', this.handleScroll)
    }
  },
  methods: {
    handleScroll($event: any): void {
      this.scrollingUp =
        this.scrollTop >= $event.target.scrollingElement.scrollTop || this.scrollTop < 20
      this.scrollTop = $event.target.scrollingElement.scrollTop
    },
    getCart(): void {
      this.$axios
        .get(`${process.env.apiUrl}/wp-json/syd/v2/cart`, {
          withCredentials: true,
        })
        .then((response: any) => {
          this.$store.commit('newCart/update', response.data)
        })
    },
    toggleOverlay(): void {
      if (this.mobileMenuActive || this.cartActive || this.loginActive) {
        this.overlayActive = true
      } else {
        this.overlayActive = false
      }
    },
    acceptCookies(): void {
      this.cookiesAccepted = true
      this.$cookies.set('acceptCookie', true, {})
    },
    toggleNavigationState(state: string): void {
      if (state == 'login') {
        this.cartActive = false
        this.mobileMenuActive = false
        if (this.isLoggedIn) {
          let path =
            this.$i18n.locale == 'en'
              ? '/en/my-account/subscription'
              : '/mitt-konto/prenumeration'
          this.$router.push(path)
        } else {
          this.loginActive = !this.loginActive
        }
      }
      if (state == 'cart') {
        this.loginActive = false
        this.mobileMenuActive = false
        this.cartActive ? (this.cartActive = false) : (this.cartActive = true)
        this.getCart()
      }
      if (state == 'menu') {
        this.loginActive = false
        this.cartActive = false
        this.mobileMenuActive
          ? (this.mobileMenuActive = false)
          : (this.mobileMenuActive = true)
      }
      this.toggleOverlay()
    },
    setAccountState(state: string): void {
      this.$root.$emit('stateChange', state)
      this.mobileMenuActive = false
    },
    closeCart(): void {
      this.cartActive = false
      this.toggleOverlay()
    },
    closeLogin(): void {
      this.loginActive = false
      this.toggleOverlay()
    },
    closeMobileMenu(): void {
      this.mobileMenuActive = false
      this.toggleOverlay()
    },
    closeAll(): void {
      this.cartActive = false
      this.loginActive = false
      this.mobileMenuActive = false
      this.toggleOverlay()
    },
    logout(): void {
      if (
        this.$nuxt.$route.path == '/mitt-konto/' ||
        this.$nuxt.$route.path == '/en/my-account/'
      )
        this.$router.push(`${this.$i18n.locale == 'en' ? '/en/' : '/'}`)
      this.$auth.logout()
    },
  },
})
