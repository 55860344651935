import { render, staticRenderFns } from "./ModalCallout.vue?vue&type=template&id=6a8d3f80&scoped=true&"
import script from "./ModalCallout.vue?vue&type=script&lang=ts&"
export * from "./ModalCallout.vue?vue&type=script&lang=ts&"
import style0 from "./ModalCallout.vue?vue&type=style&index=0&id=6a8d3f80&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a8d3f80",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SydV2SvgIcon: require('/opt/build/repo/components/v2/SvgIcon.vue').default})
